import React from "react";
import { useSettings } from "@wix/tpa-settings/react";
import {
  useEnvironment,
  useExperiments,
  useModal,
} from "@wix/yoshi-flow-editor";
import { Button, ButtonPriority } from "wix-ui-tpa";
import { classes } from "./BuyNowButton.st.css";
import settingsParams from "../../../settingsParams";
import { useControllerProps } from "../../ControllerContext";
import { useState } from "../../stores";

const BuyNowButton = () => {
  const {
    state: { checkout: checkoutState },
  } = useState();
  const settings = useSettings();
  const { isPreview } = useEnvironment();
  const { product, currencyCode } = useControllerProps().giftCardStore;

  const {
    createCheckout,
    reportCheckout,
    checkout,
    navigateToCheckout,
    creatingCheckout,
    addingToCart,
  } = useControllerProps().giftCardCheckoutStore;
  const { openModal: openPreviewBlockingModal } = useModal(
    "PreviewBlockingModal",
    {
      width: 520,
      height: 270,
      theme: "BARE",
    }
  );

  if (checkout) {
    checkout.id && navigateToCheckout(checkout.id);
  }

  return (
    <>
      <Button
        data-hook="rise-gift-card-buy-button"
        className={classes.button}
        fullWidth
        upgrade
        disabled={creatingCheckout || addingToCart}
        priority={ButtonPriority.primary}
        onClick={() => {
          if (isPreview) {
            openPreviewBlockingModal();
          } else if (product) {
            createCheckout(
              product.product_id,
              checkoutState.purchaseOptions,
              currencyCode || ""
            );
            reportCheckout({
              product,
              purchase_options: checkoutState.purchaseOptions,
              currencyCode: currencyCode || "",
              type: "buy now",
            });
          }
        }}
      >
        {settings.get(settingsParams.buyNowButtonText)}
      </Button>
    </>
  );
};

export default BuyNowButton;
