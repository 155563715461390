import React, { useEffect } from "react";
import queryString from "query-string";
import { useEnvironment, useExperiments, useBi } from "@wix/yoshi-flow-editor";
import { useSettings } from "@wix/tpa-settings/react";
import settingsParams from "../../../settingsParams";
import s from "./ProductPageBody.scss";
import Headline from "../Headline/Headline";
import ProductImage from "../ProductImage/ProductImage";
import ProductDescription from "../ProductDescription/ProductDescription";
import ProductPrice from "../ProductPrice/ProductPrice";
import ProductVariants from "../ProductVariants/ProductVariants";
import EnterCustomAmount from "../EnterCustomAmount/EnterCustomAmount";
import ProductBuyForSelection from "../ProductBuyForSelection/ProductBuyForSelection";
import ProductC2cForm from "../ProductC2cForm/ProductC2cForm";
import Quantity from "../Quantity/Quantity";
import BuyNowButton from "../BuyNowButton/BuyNowButton";
import AddToCartButton from "../AddToCartButton/AddToCartButton";
import Stack from "../Stack/Stack";
import { useControllerProps } from "../../ControllerContext";
import _ from "lodash";
import { useState } from "../../stores";

const ProductPageBody = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const { product, currencyCode, presetVariants, customVariant } =
    useControllerProps().giftCardStore;
  const { isEditor, isPreview, isViewer, isSSR, isMobile } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();
  const bi = useBi();

  const showProductVariants = !!presetVariants.length;
  const isAddToCArtButtonDisplayed =
    experiments.enabled("specs.stores.GiftCardAddToCartViewer") &&
    settings.get(settingsParams.showAddToCartButton);

  const isBuyNowButtonDisplayed =
    !experiments.enabled("specs.stores.GiftCardAddToCartViewer") ||
    settings.get(settingsParams.showBuyNowButton);

  const isC2cFormDisplayed =
    settings.get(settingsParams.giftCardFor) !== "themselves" &&
    checkout.purchaseOptions.isGift;

  useEffect(() => {
    if (!isSSR && product) {
      bi.report({
        evid: 6,
        src: 125,
        endpoint: "gc-client-sf",
        params: {
          productId: product.product_id,
          priceOptions: _.map(presetVariants, (variant) => variant.price).join(
            ","
          ),
          currency: currencyCode,
          hasDeliveryDate: settings.get(settingsParams.deliveryDate),
          viewMode: isEditor
            ? "editor"
            : isViewer
            ? "live"
            : isPreview
            ? "preview"
            : "",
          isAddToCartDisplayed: isAddToCArtButtonDisplayed,
          isBuyNowDisplayed: isBuyNowButtonDisplayed,
          settingBuyGcFor: settings.get(settingsParams.giftCardFor),
        },
      });
    }

    setPreSelectedVariant();

    if (settings.get(settingsParams.giftCardFor) === "themselves") {
      dispatch({
        type: "setPurchaseOptions",
        payload: {
          isGift: false,
        },
      });
    }
    if (settings.get(settingsParams.giftCardFor) === "both") {
      const { gift_card_for } = queryString.parse(location.search);

      switch (gift_card_for) {
        case "myself":
          dispatch({
            type: "setPurchaseOptions",
            payload: {
              isGift: false,
            },
          });
          break;
        case "someoneElse":
          dispatch({
            type: "setPurchaseOptions",
            payload: {
              isGift: true,
            },
          });
          break;
      }
    }
  }, []);

  const setPreSelectedVariant = () => {
    const { pre_selected_value, variant_id } = queryString.parse(
      location.search
    );

    if (!showProductVariants) {
      return dispatch({
        type: "setPurchaseOptions",
        payload: {
          variantId: "custom",
        },
      });
    }

    const variant = presetVariants?.find(
      ({ id, value }) =>
        id.toString() === variant_id || value.toString() === pre_selected_value
    );

    if (variant) {
      return dispatch({
        type: "setPurchaseOptions",
        payload: {
          variantId: variant.id,
        },
      });
    }

    const firstVariant = _.first(presetVariants);
    dispatch({
      type: "setPurchaseOptions",
      payload: {
        variantId: firstVariant?.id,
      },
    });
  };

  return (
    <>
      {product && (
        <main className={s.app}>
          <Stack forceColumn={isMobile} space={Stack.SpaceSize.large}>
            <Stack.Item cell="1">
              <ProductImage />
            </Stack.Item>
            <Stack.Item cell="1">
              <Headline />
              {showProductVariants && <ProductPrice />}
              <ProductDescription />
              {showProductVariants && <ProductVariants />}

              {(checkout.purchaseOptions.variantId === "custom" ||
                (!showProductVariants && customVariant)) && (
                <EnterCustomAmount />
              )}
              {settings.get(settingsParams.quantity) && <Quantity />}

              {!isSSR && (
                <>
                  {settings.get(settingsParams.giftCardFor) === "both" && (
                    <ProductBuyForSelection />
                  )}

                  {isC2cFormDisplayed && <ProductC2cForm />}
                </>
              )}
              {isAddToCArtButtonDisplayed && <AddToCartButton />}

              {isBuyNowButtonDisplayed && <BuyNowButton />}
            </Stack.Item>
          </Stack>
        </main>
      )}
    </>
  );
};

export default ProductPageBody;
