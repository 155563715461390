import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useModal } from '@wix/yoshi-flow-editor';
import { Button, ButtonPriority } from 'wix-ui-tpa';
import { classes } from './AddToCartButton.st.css';
import settingsParams from '../../../settingsParams';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';
import { SuccessIcon } from './SuccessIcon';

const AddToCartButton = () => {
  const {
    state: { checkout: checkoutState },
  } = useState();

  const settings = useSettings();
  const { isPreview } = useEnvironment();
  const { product, currencyCode } = useControllerProps().giftCardStore;
  const postAction = settings.get(settingsParams.addToCartPostAction);
  const { creatingCheckout, addingToCart } =
    useControllerProps().giftCardCheckoutStore;
  const { addToCart, reportCheckout } = useControllerProps().giftCardCheckoutStore;
  const { openModal: openPreviewBlockingModal } = useModal(
    'PreviewBlockingModal',
    {
      width: 520,
      height: 270,
      theme: 'BARE',
    },
  );
  const onAddToCartButtonClick = () => {
    if (product) {
      addToCart(
        product,
        checkoutState.purchaseOptions,
        currencyCode || '',
        postAction,
      );
      reportCheckout({
        product,
        purchase_options: checkoutState.purchaseOptions,
        currencyCode: currencyCode || '',
        type: 'add to cart',
      });
    }
  };

  return (
    <>
      <Button
        data-hook="rise-gift-card-add-to-cart-button"
        className={classes.button}
        fullWidth
        upgrade
        disabled={creatingCheckout || addingToCart}
        priority={ButtonPriority.primary}
        onClick={() => {
          if (isPreview) {
            openPreviewBlockingModal();
          } else if (product) {
            onAddToCartButtonClick();
          }
        }}
      >
        {addingToCart && postAction === 'stayOnPage' ? (
          <SuccessIcon />
        ) : (
          settings.get(settingsParams.addToCartButtonText)
        )}
      </Button>
    </>
  );
};

export default AddToCartButton;
