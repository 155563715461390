import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import {
  CheckoutState,
  checkoutInitialState,
  checkoutReducer,
  CheckoutActions,
} from './checkout';

interface State {
  checkout: CheckoutState;
}

const initialState: State = {
  checkout: checkoutInitialState,
};

const StateContext = createContext<{
  state: State;
  dispatch: Dispatch<CheckoutActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (state: State, action: CheckoutActions) => ({
  checkout: checkoutReducer(state.checkout, action),
});

export const StateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useState = () => useContext(StateContext);
